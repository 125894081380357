import React, { Fragment, useState, useEffect } from "react";
import Router, { useRouter } from "next/router";
import DateRangePicker from "react-daterange-picker";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { actions } from "../../redux/Frontend/ListingsFilter/redux/slice";
import { SelectListingForm } from "../../redux/Frontend/ListingsFilter/redux/selector";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore
const moment = extendMoment(Moment);

function FilterDate({
  customDate,
  _showCalender,
  showCalender,
  _handleRetreatDate,
  filterData,
  customDateSuggestion,
}: any) {
  const router = useRouter();

  const today = moment();
  const listingForm = useSelector(SelectListingForm);
  const [selectedDate, setSelectedDate] = useState(today);
  const [activetab, setActiveTab] = useState("No Dates");
  const dispatch = useDispatch();
  const _handletab = (value: any) => {
    dispatch(
      actions.updateListingsFilterFormValue({
        key: "date",
        value: "",
      })
    );
    setActiveTab(value);
    _showCalender();

    _handleRetreatDate("");
  };

  const _handleDisplayDate = (val: any) => {
    if (val.length > 0)
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "date",
          value: val,
        })
      );
  };

  const onSelectRange = (value: any) => {
    const date = `${moment(value).format()}`;

    dispatch(
      actions.updateListingsFilterFormValue({
        key: "date",
        value: date,
      })
    );

    _handleRetreatDate(moment(value).format());
    _showCalender();
  };

  return (
    <>
      <div className={`${customDate}  d-flex custom-date `}>
        {showCalender && (
          <div
            className={`search-component-suggestion bg-white p-3 text-center ${customDateSuggestion}`}
          >
            <div className="d-flex flex-column byr-category-button-wrap">
              <div className="d-flex flex-row justify-content-center">
                <button
                  className={`${
                    activetab == "Specific Dates"
                      ? "btn-outline-primary-active"
                      : "btn-outline-primary btnHoverNew"
                  } py-2 btn fs-12 shadow-none btnHover`}
                  onClick={() => setActiveTab("Specific Dates")}
                  style={{
                    width: "120px",
                    borderTopRightRadius: "unset",
                    borderBottomRightRadius: "unset",
                  }}
                >
                  Specific Dates
                </button>
                <button
                  className={`${
                    activetab != "No Dates"
                      ? "btn-outline-primary btnHoverNew"
                      : "btn-outline-primary-active"
                  }  py-2 btn fs-12 shadow-none btnHover `}
                  style={{
                    width: "120px",
                    borderTopLeftRadius: "unset",
                    borderBottomLeftRadius: "unset",
                  }}
                  onClick={() => _handletab("No Dates")}
                >
                  No Dates
                </button>
              </div>

              {activetab == "No Dates" ? (
                <div className="d-flex flex-wrap py-3 justify-content-center">
                  <p className="fs-12 m-0">
                    No dates selected,showing retreats for all dates
                  </p>
                </div>
              ) : (
                <div className="mt-2">
                  {showCalender && (
                    <DateRangePicker
                      selectionType="single"
                      value={listingForm?.date}
                      onSelect={onSelectRange}
                      minimumDate={new Date()}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {/*----------input-------------------------------------------------------------------------  */}
        <div
          className="col-12 d-flex flex-row align-items-center justify-content-start px-md-3 px-3 py-2 pointer"
          onClick={_showCalender}
        >
          <img
            src="/images/calendar-icon.svg"
            className="img-fluid"
            height="14px"
            width="14px"
          />
          {/* <p className="fs-12 m-0 mx-2  py-1" style={{color:"#5e5e5e", opacity:"0.7"}}>Select Date</p> */}

          <input
            type="text"
            placeholder="Select Date"
            value={
              listingForm?.date.length > 0
                ? moment(listingForm?.date)?.format("DD/MM/YYYY")
                : ""
            }
            onChange={() => _handleDisplayDate(listingForm.date)}
            className="col-10 fs-12 fw-500 px-0 border-0 rounded-0 mx-2 pointer"
          />
        </div>
        {/* --------------------------------------------------------------------------------------------------------------- */}
      </div>
    </>
  );
}
export default FilterDate;
