import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { selectAllActiveCurrencyListData } from "../redux/School/SchoolPayments/redux/selector";
import { actions } from "../redux/School/SchoolPayments/redux/slice";
import { log } from "../utils/logger";
const currencyshow = ({ type, value, className, style }: any) => {
  const [currency, setCurrency] = useState("USD");

  useEffect(() => {
    if (getCookie("currencyType").length > 0) {
      setCurrency(getCookie("currencyType"));
    }
  }, []);

  const Currencies = useSelector(selectAllActiveCurrencyListData);

  useEffect(() => {
    log("Currencies", Currencies);

    return () => {};
  }, [Currencies]);

  const renderCurrencySymbol = () => {
    const val = Currencies.filter((x) => x.code == currency);
    if (val.length > 0) {
      return val[0].symbol;
    } else {
      return "$";
    }
  };

  return (
    <span className={className} style={{ ...style }}>
      <CurrencyFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={renderCurrencySymbol() + " "}
      />
    </span>
  );
};

const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
export default currencyshow;
