import React, { useRef, useEffect } from "react";

const ClickOutside = ({ children, clickHandler }: any) => {
  const ref = useRef<any>(null);

  const handleClick = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      clickHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return <div ref={ref}>{children}</div>;
};

export default ClickOutside;
