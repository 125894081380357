import React, { Fragment, useState, useEffect } from "react";
import useSearchRetreats from "../../hooks/useSearchRetreats";
import Spinner from "react-bootstrap/Spinner";
import { actions } from "../../redux/Frontend/ListingsFilter/redux/slice";
import FilterDate from "./FilterDate";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectListingForm,
  SelectSearchLog,
  SelectSearchLogSearch,
} from "../../redux/Frontend/ListingsFilter/redux/selector";
import { Modal } from "@mui/material";
import ClickOutside from "../Admin/ClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import CustomToast from "./CustomToast";
import { log } from "../../utils/logger";
import { string } from "yup";

function Search(props: any) {
  const router = useRouter();
  const city = router.query.city;
  const country = router.query.country;
  const {
    filterData,
    _handleChange,
    customSearch,
    customDate,
    customButton,
    customSearchSuggesion,
    customDateSuggestion,
    children,
  } = props;
  const [isSearch, setIsSeach] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [popularLoading, setPopularLoading] = useState(false);
  const [showPopular, setShowPopular] = useState(false);
  const [showCalender, setshowCalender] = useState(false);

  const dispatch = useDispatch();
  const [retreatDate, setretreatDate] = useState<any>([]);
  const [filteredSearchData, setFilteredSearchData] = useState<any>([]);

  const _showCalender = () => {
    setshowCalender(!showCalender);
    setShowPopular(false);
    setIsSeach(false);
  };
  const _showPopular = () => {
    setshowCalender(false);
    setShowPopular(!showPopular);
    setIsSeach(false);
    setPopularLoading(true);
  };
  const listingForm = useSelector(SelectListingForm);
  const searchLog = useSelector(SelectSearchLog);
  useEffect(() => {
    if (city?.length == 0 && country?.length == 0) {
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "country",
          value: "",
        })
      );
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "city",
          value: "",
        })
      );
    }
    setretreatDate(listingForm.date);
  }, [listingForm.date,city,country]);

  const _handleRetreatDate = (date: any) => {
    setretreatDate(date);
    dispatch(
      actions.updateListingsFilterFormValue({
        key: "date",
        value: date,
      })
    );
    _handleChange({});
  };

  // const { loading, error, hasMore, searchData } = useSearchRetreats(
  //   pageNumber,
  //   searchName,
  //   isSearch
  // );

  // useEffect(() => {
  //   setFilteredSearchData(searchData);
  // }, [searchData]);

  const observer: any = React.useRef();

  useEffect(() => {
    dispatch(actions.DoGetSearchLogList());
  }, [showPopular]);
  // ---------------------------------------------------------------------------------------------------------------
  let regex = /^[A-Za-z0-9\s]*$/;
  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageNumber(1);
    const { value, name } = e.target;

    if (value.trim().length > 0) {
      if (regex.test(value)) {
        dispatch(
          actions.updateListingsFilterFormValue({
            key: "search",
            value: value,
          })
        );
        dispatch(actions.setsearchlogsearch(value.trim()));
      }
    } else {
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "search",
          value: value,
        })
      );
      dispatch(actions.setsearchlogsearch(value.trim()));
    }
    // _handleChange({});
    if (value.length >= 1) {
      setIsSeach(true);
    } else {
      setIsSeach(false);
    }
  };

  const searhlogsearch = useSelector(SelectSearchLogSearch);
  useEffect(() => {
    dispatch(actions.DoGetSearchLogList());
  }, [searhlogsearch]);
  const [redirectName, setRedirectName] = useState("");
  const [cityValue, setCityValue] = useState("");
  const restreatSearch = () => {
    setIsSeach(false);
    setShowPopular(false);
    setshowCalender(false);
    if (
      listingForm.search == "" &&
      listingForm.city == "" &&
      listingForm?.date == "" &&
      //@ts-ignore
      listingForm?.activityFilter == []
    ) {
      CustomToast("Please fill the data", "ERROR");
      return;
    } else {
      router.push({
        pathname: "/listing",
        query: { ...listingForm },
      });
      if (redirectName == "search") {
        router.push({
          pathname: "/listing",
          query: { ...listingForm },
        });
        // dispatch(actions.clearSearch());
      } else if (redirectName == "location") {
        router.push({
          pathname: "/listing",
          query: { ...listingForm },
        });
        dispatch(actions.clearSearch());
        dispatch(
          actions.clearLocationFilterForm({
            callback() { },
          })
        );
      } else if (redirectName == "activity") {
        router.push({
          pathname: "/listing",
          query: { ...listingForm },
        });
        dispatch(actions.clearSearch());
      }
    }
  };
  const handleSearch = (e: any, name: string) => {
    if (name == "location") {
      setRedirectName(name);
      setCityValue(e.target.textContent);
      const string_value = e.target.textContent;
      const split_values = string_value.split(",");

      dispatch(
        actions.updateListingsFilterFormValue({
          key: "country",
          value: split_values[1],
        })
      );
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "city",
          value: split_values[0],
        })
      );
      dispatch(actions.clearSearch());
      dispatch(actions.clearActivitySearch());
    } else if (name == "activity") {
      setRedirectName(name);
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "activityFilter",
          value: e.target.textContent,
        })
      );
      dispatch(actions.clearSearch());
      dispatch(
        actions.clearLocationFilterForm({
          callback() { },
        })
      );
    } else if (name == "search") {
      setRedirectName(name);
      dispatch(
        actions.updateListingsFilterFormValue({
          key: "search",
          value: e.target.textContent,
        })
      );
      dispatch(
        actions.clearLocationFilterForm({
          callback() { },
        })
      );
      dispatch(actions.clearActivitySearch());
    }

    setShowPopular(false);
  };
  const handleClickClose = () => {
    setshowCalender(false);
    setShowPopular(false);
    setIsSeach(false);
    setPopularLoading(true);
  };
  return (
    <ClickOutside clickHandler={handleClickClose}>
      <div className=" search">
        <div className="d-flex flex-column flex-md-row  justify-content-between px-0  col-12">
          <div className={`${customSearch} d-flex py-1 custom_search`}>
            <div className="px-2  d-flex align-items-center justify-content-center bg-white ">
              <img
                src="/images/search.svg"
                className="mx-1"
                height="18"
                width="18"
              />
            </div>
            {redirectName == "search" ? (
              <input
                type="text"
                placeholder="You are about to find the best yoga experience"
                aria-label="retreat"
                aria-describedby="basic-addon1"
                onChange={(e) => handleOnSearch(e)}
                onClick={_showPopular}
                value={listingForm.search}
                className="col-10 fs-12 fw-500 px-0 border-0 rounded-0 py-2"
              />
            ) : redirectName == "location" ? (
              <input
                type="text"
                placeholder="You are about to find the best yoga experience"
                aria-label="retreat"
                aria-describedby="basic-addon1"
                onChange={(e) => handleOnSearch(e)}
                onClick={_showPopular}
                value={cityValue}
                className="col-10 fs-12 fw-500 px-0 border-0 rounded-0 py-2"
              />
            ) : redirectName == "activity" ? (
              <input
                type="text"
                placeholder="You are about to find the best yoga experience"
                aria-label="retreat"
                aria-describedby="basic-addon1"
                onChange={(e) => handleOnSearch(e)}
                onClick={_showPopular}
                value={listingForm.activityFilter}
                className="col-10 fs-12 fw-500 px-0 border-0 rounded-0 py-2"
              />
            ) : (
              <input
                type="text"
                placeholder="You are about to find the best yoga experience"
                aria-label="retreat"
                aria-describedby="basic-addon1"
                onChange={(e) => handleOnSearch(e)}
                onClick={_showPopular}
                value={listingForm.search}
                className="col-10 fs-12 fw-500 px-0 border-0 rounded-0 py-2"
              />
            )}
          </div>

          {showPopular && (
            <div
              className={`${customSearchSuggesion} search-component-suggestion bg-white`}
              style={{ zIndex: 9999 }}
            >
              <div className="height">
                {searchLog?.search?.length > 0 ||
                  searchLog?.activity?.length > 0 ||
                  searchLog?.listings?.length > 0 ||
                  searchLog?.locations?.length > 0 ? (
                  <>
                    {listingForm.search.length == 0 ? (
                      <>
                        {searchLog?.search?.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                              Popular Searches
                            </p>
                            <hr className="my-2" />
                            {searchLog?.search.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "search")
                                        }
                                      >
                                        {data?.searchKeyword}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                        {searchLog?.listings?.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                              listings
                            </p>
                            <hr className="my-2" />
                            {searchLog?.listings.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "search")
                                        }
                                      >
                                        {data?.retreatTitle}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                        {searchLog?.locations?.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                              Destinations:
                            </p>
                            <hr className="my-2" />
                            {searchLog?.locations.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "location")
                                        }
                                      >
                                        {data?.city},{data?.country}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                        {searchLog.activity.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                              Categories:
                            </p>
                            <hr className="my-2" />
                            {searchLog?.activity.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "activity")
                                        }
                                      >
                                        {data?.name}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                      </>
                    ) : listingForm.search.length > 0 ? (
                      <>
                        {searchLog?.locations?.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                              Destinations:
                            </p>
                            <hr className="my-2" />
                            {searchLog?.locations.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "location")
                                        }
                                      >
                                        {data?.city},{data?.country}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                        {searchLog?.listings?.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                            listings:
                            </p>
                            <hr className="my-2" />
                            {searchLog?.listings.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "search")
                                        }
                                      >
                                        {data?.retreatTitle}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                        {searchLog.activity.length > 0 && (
                          <>
                            <p className="fs-12 fw-bold my-2 mx-3">
                              Categories:
                            </p>
                            <hr className="my-2" />
                            {searchLog?.activity.map(
                              (data: any, index: number) => {
                                return (
                                  <>
                                    <div className="data-row " key={index}>
                                      <p
                                        className="fs-12 my-1 mx-3 pointer"
                                        onClick={(e) =>
                                          handleSearch(e, "activity")
                                        }
                                      >
                                        {data?.name}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <div className="text-center mt-3 pb-3 fs-11">
                      We&lsquo;re sorry. We cannot find any matches for your
                      search term.
                    </div>{" "}
                    <hr className="my-2" />
                    {searchLog?.defaultSearch?.length > 0 && (
                      <>
                        <p className="fs-12 fw-bold my-2 mx-3">
                          Popular Searches
                        </p>

                        {searchLog?.defaultSearch.map(
                          (data: any, index: number) => {
                            return (
                              <>
                                <div className="data-row " key={index}>
                                  <p
                                    className="fs-12 my-1 mx-3 pointer"
                                    onClick={(e) => handleSearch(e, "search")}
                                  >
                                    {data?.searchKeyword}
                                  </p>
                                </div>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <FilterDate
            customDate={customDate}
            filterData={filterData}
            _showCalender={_showCalender}
            showCalender={showCalender}
            _handleRetreatDate={_handleRetreatDate}
            restreatSearch={restreatSearch}
            customDateSuggestion={customDateSuggestion}
          />

          <div className={`${customButton}  d-flex justify-content-between `}>
            <button
              className="d-flex  fw-500 align-items-center justify-content-center bg-dark-blue text-white  w-100 fs-12 px-2 search-btn b-none py-3 py-md-0 search-button-width"
              onClick={restreatSearch}
            >
              {" "}
              {/* <img
                src="/images/loupe.svg"
                className="img-fluid me-2"
                height="15px"
                width="15px"
              /> */}
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                height="15px"
                width="15px"
                className="img-fluid me-2"
              />
              Discover Now
            </button>

            {children}
          </div>
        </div>
      </div>
    </ClickOutside>
  );
}
export default Search;
